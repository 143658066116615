.ant-table-header, .ant-table-thead > tr > th {
    background: #343C43;
    color: #5F6B78;
    font-size: 11px;
    text-transform: uppercase;
}

.ant-table-thead > tr > th {
    border-bottom: 1px solid #5F6B78;
}

.ant-table-tbody > tr > td {
    border-bottom-color: transparent;
}

.ant-table-tbody > tr.ant-table-row:nth-child(2n+1) > td,
.ant-table-tbody > tr.ant-table-row:nth-child(2n+1):hover > td {
    background: #2B343A;
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: #4e626b;
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {

}

.ant-table-scroll {
    overflow: auto;
    overflow-x: hidden;
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: transparent;
}


.ant-table-wrapper, .ant-spin-nested-loading, .ant-spin-container {
    height: 100%;
}

.ant-table {
    height: calc(100% - 46px);
}

.table-stat {
    position: absolute;
    bottom: 0;
    right: 0;
    line-height: 30px;
    height: 30px;
    color: #596570;
    font-size: 12px;
}

.ant-table-placeholder {
    background: #343C43;
    border-radius: 0;
    border-bottom: 1px solid transparent;
}

.ant-table-thead > tr > th .ant-table-column-sorter {
    color: #596570;
}

.ant-table-thead > tr > th .anticon-filter, .ant-table-thead > tr > th .ant-table-filter-icon {
    color: #596570;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover {
    background: transparent;
}
.ant-table-thead > tr > th.ant-table-column-sort {
    color: #8e9ca8;
    background: transparent;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter,
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon {
    background: transparent;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover, .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:hover {
    color: #616A75;
    background: transparent;
}

.ant-table-thead > tr > th .ant-table-filter-selected.anticon-filter {
    color: #40a9ff!important;
}

.ant-dropdown-menu-item-selected, .ant-dropdown-menu-submenu-title-selected, .ant-dropdown-menu-item-selected > a, .ant-dropdown-menu-submenu-title-selected > a {
    color: #40a9ff;
    background-color: transparent;
}


.table-top-main {
    display: flex;
    align-items: center;
}

.table-top-main-start {
    flex: 1;
}

.table-top-main-end {
    margin-left: 16px;
}

.table-filters {
    display: flex;
    padding: 16px 0;
    color: #7a8895;
    font-size: 12px;
    line-height: 1;
}

.table-filters-item {
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.table-filters-item-label {
    margin-right: 5px;
}

.table-filters-item-values {
    display: flex;
}